import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AreaService } from 'src/app/services/area.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { PreviewComponent } from 'src/app/shared/component/preview/preview.component';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-new-usuario',
  templateUrl: './new-usuario.component.html',
  styleUrls: ['./new-usuario.component.css']
})
export class NewUsuarioComponent implements OnInit {

  form: FormGroup = new FormGroup({});
  hide = true;

  file: File = {} as File;
  fileName: string = '';

  urlImage: string = '';

  listAreas: any = [];

  opc_new: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<NewUsuarioComponent>, 
    @Inject(MAT_DIALOG_DATA) public data:any, 
    private formBuilder:FormBuilder, 
    private usuarioService: UsuarioService,
    private alert: AlertService,
    private dialog: MatDialog
  ) {
    if (data == 'new-admin') {
      this.opc_new = true;
    }
  }

  ngOnInit(): void {
    this.cargarFormulario();
    this.cargarDatos();
  }

  cargarFormulario() {
    if (this.opc_new) {
      this.form = this.formBuilder.group({
        nombre:  ['', [Validators.required]],
        email:  ['', [Validators.required]],
        nro_documento:  ['', [Validators.required]],
        codigo:  ['', [Validators.required]],
        birth_date:  [''],
        archivo: ['']
      });
    } else {
      this.form = this.formBuilder.group({
        nombre: [{ value: ''}],
        email: [{ value: ''}],
        nro_documento: [{ value: ''}],
        codigo:  ['', [Validators.required]],
        birth_date:  [''],
        archivo: ['']
      });
    }
  }

  cargarDatos() {
    this.form.reset({
      nombre: this.data.name,
      email: this.data.email,
      nro_documento: this.data.number_document,
      codigo: this.data.codigo,
      birth_date: moment(this.data.birth_date).format()
    });
    //--- Mostrar imagen -----
    if (this.data.photo_id > 0) {
      this.urlImage = (this.data.file.route!='' ? environment.url + '/' + this.data.file.route : '');
    }
  }

  guardar() {
    if (this.form.invalid) {
      this.alert.showInfo("invalid");
      return;
    }

    const formData = new FormData();
    formData.append('name', this.form.value.nombre);
    formData.append('number_document', this.form.value.nro_documento);
    formData.append('email', this.form.value.email);
    formData.append('user', this.form.value.nro_documento);
    formData.append('codigo', this.form.value.codigo);
    formData.append('password', this.form.value.codigo);

    this.usuarioService.postUsuario(formData)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        else {
          this.alert.showSave('ok');
          this.dialogRef.close(true);
        }
      },
      (err) => {
        this.alert.showSave('error');
      });
  }

  actualizar() {
    if (this.form.invalid) {
      this.alert.showInfo("invalid");
      return;
    }

    let birth_date = this.form.value.birth_date ? moment(this.form.value.birth_date).format() : '';
    
    const formData = new FormData();
    formData.append('name', this.form.value.nombre);
    formData.append('number_document', this.form.value.nro_documento);
    formData.append('email', this.form.value.email);
    formData.append('user', this.form.value.nro_documento);
    formData.append('codigo', this.form.value.codigo);
    formData.append('password', this.form.value.codigo);
    formData.append('birth_date', birth_date );
        
    if (this.file) {
      formData.append('photo', this.file);
    }

    this.usuarioService.postUpdateUsuario(this.data.id, formData)
      .subscribe((res: any) => {
        if (res.data.status !== 200) {
          this.alert.showErrorCustom(res.data.mensaje);
          return;
        }
        this.alert.showUpdate("ok");
        this.dialogRef.close(true);
      },
      (err) => {
        this.alert.showUpdate("error");
      });
  }

  /**
   * Subir imagen
   */
   onFileSelected(event: any) {
    const file = event.target.files[0];
    const extensions = ['JPG','JPEG','PNG'];
    const size = file.size;
    const maxSize = 5 * 1000000;
    let extName = this.getExtension(file.name);

    if (size > maxSize) {
      this.alert.showInfoCustom(`El archivo excede el peso máximo de ${maxSize / 1000000}Mb`);
    } else {
      if (extensions.indexOf(extName) > -1) {
        this.file = event.target.files[0];
        if (this.file) {
          this.fileName = this.file.name;
        }
      } else {
        this.alert.showInfoCustom(`Solo se permiten archivos con las siguientes extensiones jpg, jpeg, png`);
      }
    }
  }

  getExtension(name: string) {
    let splitNames = name.split('.');
    return (splitNames[splitNames.length - 1]).toUpperCase();
  }

  visualizarImagen() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      url_image: this.urlImage
    };
    dialogConfig.panelClass = 'preview-img-dialog';
    dialogConfig.width = '400px';
    dialogConfig.height = '400px';

    this.dialog.open(PreviewComponent, dialogConfig);
  }

}
